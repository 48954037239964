import './App.css';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import { Home } from './components/Home';
import { InserzioniFarmacie } from './components/inserzioni-farmacie';
import { Farmacieinvendita } from './components/farmacie-in-vendita';
import { Farmaciainvendita } from './components/farmacia-in-vendita';
import { CompravenditaFarmacie } from './components/compravendita-farmacie';
import { ComprareUnaFarmacia } from './components/comprare-una-farmacia';
import { VendereUnaFarmacia } from './components/vendere-la-farmacia';
import { Chisiamo } from './components/chi-siamo';
import { Consigli } from './components/consigli';
import { Cookies } from './components/cookies';
import { Condizioni } from './components/condizioni';
import { Privacy } from './components/privacy';
import { Footer } from './components/footer';
import { NotFound } from './components/NotFound';
import { NonDisponibile } from './components/NonDisponibile';
import ScrollToTop from "./components/ScrollToTop";

function App() {

  return (
    <BrowserRouter>
      <div className="App">
        <div>
          <Routes>
            <Route path='cookies' element={<Cookies />} />
            <Route path='condizioni' element={<Condizioni />} />
            <Route path='privacy' element={<Privacy />} />
            <Route path='/' element={<Home />} />
            <Route path='farmacie-in-vendita' element={<Farmacieinvendita />} />

            <Route path='InserzioniFarmacie/:regione' element={<InserzioniFarmacie />} />

            <Route path='chi-siamo' element={<Chisiamo />} />
            {/*
            <Route path='servizi' element={<Servizi />} />
            */}
            <Route path='consigli' element={<Consigli />} />
            <Route path='compravendita-farmacie' element={<CompravenditaFarmacie />} />
            <Route path='comprare-una-farmacia' element={<ComprareUnaFarmacia />} />
            <Route path='vendere-la-farmacia' element={<VendereUnaFarmacia />} />
            <Route path='farmacia-in-vendita/:url_pagina' element={<Farmaciainvendita />} />
            <Route path='*' element={<NotFound />} />
            <Route path='non-disponibile' element={<NonDisponibile />} />

          </Routes>
        </div>
        <ScrollToTop />
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
