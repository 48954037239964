import {useNavigate } from 'react-router-dom';

export const Ritorna = () => {
  
  const navigate = useNavigate();

  return (
    <>
          <div className="p-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">Navigazione</h4>
            <button type="button" className="btn btn-warning btn-md" onClick={() => navigate(-1)}>Ritorna alla pagina precedente
            </button>

          </div>
    </>
  )
}
export default Ritorna;