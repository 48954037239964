
import Farmacontatto_consigli from './Immagini/Farmacontatto-consigli.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ContattiBottone from './ContattiBottone';

export const Consigli_Head = () => {
  return (
    <>


      <HelmetProvider>
        <Helmet>
          <title>Consigli sulla compravendita di farmacie - Farmacontatto</title>
          <meta name="description" content={'⭐ I consigli di Farmacontatto sulla compravendita di farmacie. ⭐ Come comprare una farmacia. ⭐ Come vendere la tua farmacia.'} />
        </Helmet>
      </HelmetProvider>

      <div className="px-5 text-center border-bottom" id='Compravendita di farmacie' style={{ backgroundColor: "#dddddd" }}>
        <div className="mycontainer col-xxl-12">
          <div className="row align-items-center ">
            <div className="col pt-5 col-sm-12 col-lg-6">
              <img src={Farmacontatto_consigli} className="d-block mx-lg-auto img-fluid" alt="Come comprare o vendere una farmacia" title="Come comprare o vendere una farmacia" width="700" height="500" loading="lazy" />
            </div>
            <div className="col-lg-6 mb-3"
              style={{
                borderRadius: '20px',
                backgroundColor: '#f7f9fc',
                padding: '20px'
              }}>
              <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3"
                style={{
                  textShadow: '-3px 3px 6px #A39090',
                  fontSize: '5VW',
                  WebkitTextStrokeWidth: '1px',
                  WebkitTextStrokeColor: '#54595f'
                }}>
                <font color="#5672AD">Consigli di</font><br></br>
                <font color="#5672AD">Farma</font><font color="#8fc963">con</font><font color="#5672AD">tatto</font></h1>
              <p className="lead fw-bold"
                style={{
                  textShadow: '-1px 1px 2px #A39090',
                  fontSize: '2.5VW',
                }}>
                <font color="#5672AD">
                  ecco qualche consiglio per comprare o vendere una farmacia
                </font>
              </p>
              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <ContattiBottone etichetta='Contattaci' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Consigli_Head;