import { ComprareUnaFarmacia_Toc } from './comprare_una_farmacia_toc';
import { Pharmascout_Sb } from './pharmascout_sb';
import { AltrePagine_Sb } from './altre_pagine_sb';

export const ComprareUnaFarmacia_Sb = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>

          <ComprareUnaFarmacia_Toc />
          <AltrePagine_Sb />
          <Pharmascout_Sb />
        
        </div>
      </div>
    </>
  )
}

export default ComprareUnaFarmacia_Sb;