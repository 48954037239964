import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { Consigli_Head } from './consigli_head';
import { Consigli_Rg1 } from './consigli_rg1';
import { Consigli_Rg2 } from './consigli_rg2';
import { Consigli_Rg3 } from './consigli_rg3';
import { Consigli_Rg4 } from './consigli_rg4';
import { Home_Rg4 } from './Home_Rg4';

export const Consigli = () => {

  return (
    <>
      <Navbar1 />
      <Consigli_Head />
      <Consigli_Rg1 />
      <Consigli_Rg2 />
      <Consigli_Rg3 />
      <Consigli_Rg4 />

      <Home_Rg4 />
    </>
  )
}