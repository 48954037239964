import AnchorLink from 'react-anchor-link-smooth-scroll';

export const ComprareUnaFarmacia_Toc = () => {
  return (
    <>
          <div className="p-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign: 'left', fontSize: '1em'}}>
              <AnchorLink offset='100' href='#Comprare una farmacia'>Comprare una farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Una storia vera'>Ti racconto una storia vera</AnchorLink>
            </p>
          </div>
    </>
  )
}
export default ComprareUnaFarmacia_Toc;