import React, { Component, useEffect, useState } from "react";
import {Link} from "react-router-dom";

export const Regioni_Sb = () => {
  const [DatiJson, setUsers] = useState([])
  const [DatiTipo, setTip] = useState([])
  const [DatiTotale, setTot] = useState([])

  const fetchTotData = () => {
    fetch("https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=Tot&t=FC")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setTot(data)
      })
  }
  useEffect(() => {
    fetchTotData()
  }, [])

  const fetchUserData = () => {
    fetch("https://org.farmacontatto.it/PhsCrm/inserzioni/esegui/get_inserzioni_web.php?r=Reg&t=FC")
      .then(response => {
        return response.json()
      })
      .then(data => {
        setUsers(data)
      })
  }
  useEffect(() => {
    fetchUserData()
  }, [])


  {/* Lasciare <a href= ... e non Link perché deve fare 
      refresh sul server e non lavorare solo sul client
      come farebbe con Link mediante router-dom
  */}

  return (
    <>
      <div>
        <h4 className="fst-italic pt-2">Inserzioni Farmacie</h4>
        <div className="row flex-md-row pt-1">
          {DatiJson.length > 0 && (
            <>
              <div className="col-md-12">
                {DatiTotale.map(ftot => (
                  <div className="row mb-1" key={ftot.id}>
                    <div className="col-md-12 text-center">
                      <Link to={"/farmacie-in-vendita/"}><b>Totale inserzioni ({ftot.totale})</b>
                      </Link>
                    </div>
                    <div className="col-md-12 text-center">
                      <a href={"/InserzioniFarmacie/Vendita"}><b>Farmacie in Vendita ({ftot.vendite})</b>
                      </a>
                    </div>
                    <div className="col-md-12 text-center">
                    <a href={"/InserzioniFarmacie/Ricerche"}><b>Ricerca Farmacie ({ftot.ricerche})</b>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <h4 className="fst-italic pt-2">Per Regione</h4>
              <div className="col-md-12">
                {DatiJson.map(farma => (
                  <div className="row mb-1" key={farma.id}>
                    <div className="col-md-12 text-center">
                    <a href={"/InserzioniFarmacie/" + farma.regione}><b>{farma.regione} ({farma.numero})</b>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
          <div className="col-12" id='Comprare una farmacia'>
          </div>
        </div>


      </div>
    </>
  )
}
export default Regioni_Sb;