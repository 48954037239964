import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { Home_Head } from './Home_Head';
import { Home_Rg1 } from './Home_Rg1';
import { Home_Rg2 } from './Home_Rg2';
import { Home_Rg3 } from './Home_Rg3';
import { Home_Rg4 } from './Home_Rg4';
import { Home_Rg5 } from './Home_Rg5';

export const Home = () => {

  return (
    <>
      <Navbar1 />
      <Home_Head />
      <Home_Rg1 />
      <Home_Rg2 />
      <Home_Rg3 />
      <Home_Rg5 />
          {/*
           */} 
      <Home_Rg4 />
    </>
  )
}

export default Home;