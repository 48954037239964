import { ComprareUnaFarmacia_Sb } from './comprare_una_farmacia_sb';
import ContattiLink from './ContattiLink';
import ContattiBottone from './ContattiBottone';

export const ComprareUnaFarmacia_Corpo = () => {

  return (
    <>
      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">

            <article className="blog-post p-3"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <p className="blog-post-meta"> Luglio, 2023 di 
              <ContattiLink nome=' Achille Bonzini'/>
              </p>
              <div className="row flex-md-row">


                <div className="col-12" id='Comprare una farmacia'>
                  <p style={{ color: '#f57e20', fontWeight: 'bold' }}>COMPRARE UNA FARMACIA</p>
                  <div style={{ textAlign: 'left' }} id='Una storia vera'>
                    <h3>Ed ora ti racconto una storia vera</h3>
                    <p className="my-citazione">
                    “L’ovvio é quello che non si vede mai, finché qualcuno non lo esprime con la massima semplicità.”<br></br> Kahlil Gibran
                    </p>
                    <p>
                    Hai mai provato a guardare quanti annunci di appartamenti privati in vendita ci sono sui giornali e in internet? …migliaia. E quanti annunci simili per comprare una farmacia hai trovato? …nessuno o quasi.
                    </p>
                    <p>
                    Ti sei mai chiesto perché quasi nessuno fa inserzioni per vendere o comprare una farmacia? O almeno non lo fa dove si fanno tutte le altre inserzioni “ordinarie”.
                    </p>
                    <p>
                    Marco era il giovane direttore di una farmacia comunale da diversi anni, l’aveva gestita bene, per orgoglio personale e al massimo delle proprie capacità, anche se a fronte di uno stipendio appena sufficiente.
                    </p>
                    <p>
                    Da tempo coltivava il sogno di comprare una farmacia e sperava che un giorno il Comune l’avrebbe venduta, come voci di corridoio lasciavano intendere. Lui, essendo il direttore, aveva la prelazione e con l’aiuto dei suoi aveva messo da parte qualche soldo ben sapendo che non sarebbe bastato.
                    </p>
                    <p>
                    Ma il tempo passava e il Comune non sembrava voler vendere.
                    </p>
                    <p>
                    Stanco di aspettare inizia a rivolgersi ai rappresentanti e ad un famoso commercialista di farmacie della zona. Dai primi tante promesse ma niente di concreto e dal secondo, pure, e la famosa telefonata non arrivava mai.
                    </p>
                    <p>
                    Oggi con Marco siamo diventati amici, tutto è filato liscio. A distanza di due anni ha fatto ristrutturare la sua farmacia - che ne aveva proprio bisogno - e orgoglioso qualche giorno fa mi chiama per farmela vedere.
                    </p>
                    <p>
                    “Sai” mi ha detto, “è venuto a trovarmi Luca per farmi gli auguri di Natale e mi ha detto di salutarti. E’ stato molto contento di vedere come ho sistemato la sua vecchia farmacia e, ancor di più, nel vedere che ho valorizzato quel bel mobile antico che mi ha lasciato e al quale teneva tanto perché era di suo nonno. Non ha rimpianti, è in pensione e si gode i nipotini", poi, prosegue, “mi capita a volte di passare davanti alla vecchia comunale, é rimasto tutto come prima …o quasi, e non l’hanno venduta".
                    </p>
                    <p>
                    "Il nuovo direttore della farmacia Comunale è uno ragazzo giovane ed è pure simpatico, ma, il sentore e gli odori in quella vecchia farmacia, me li ricordo bene, ora sono, come dire …diversi, o almeno così mi sembra. Chissà, forse sarà che sono cambiato io”.
                    </p>
                    <p>
                    Comprare una farmacia è impresa delicata e complessa, venderla lo è forse ancora di più. Io vendo, per lavoro, e compro farmacie tutti i giorni, nella massima riservatezza, e se chiedi a Marco, o a Luca, ti diranno che è stato facile….
                    </p>
                    <p>
                    Perché …vedi, a monte c’è un lavoro quasi maniacale per far diventare semplice quello che è complesso e che altri, al contrario, preferiscono complicare ulteriormente spesso solo per darsi importanza. Noi, per fare un’esempio, ci sforziamo di scrivere i contratti in linguaggio semplice e in un italiano chiaro, al limite dell’ovvio, perché “L’ovvio è quello che non si vede mai, finché qualcuno non lo esprime con la massima semplicità.” Kahlil Gibran.
                    </p>
                    <p><b>
                    Achille Bonzini
                    </b>
                    </p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 pt-4"
                    style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
                  >
                    <div className="row g-0 overflow-hidden flex-md-row">
                      <div className="col d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

                        <h2 className="d-inline-block mb-2 text-primary-emphasis">E TU, COSA PERNSI DI FARE? </h2>
                        <h3 className="mb-0 text-primary-emphasis mb-3">Vorresti comprare una farmacia o ne hai una da vendere?
                          <br></br>Perché dovresti scegliere noi e non altri?</h3>

                        <p className="card-text mb-auto">
                        <ContattiBottone etichetta=' Contattami'/>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>
            </article>
          </div>
          < ComprareUnaFarmacia_Sb />
        </div>
      </div>

    </>
  )
}
export default ComprareUnaFarmacia_Corpo;