import ContattiBottone from './ContattiBottone';

export const Home_Rg4 = () => {
  return (
    <>
      <div className="row">
        <div className="col-12 p-4"
          style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
        >
          <div className="row g-0 overflow-hidden flex-md-row">
            <div className="col p-4 d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

              <h4 style={{ color: '#f57e20', fontWeight: 'bold' }}>INOLTRE</h4>{' '}
              <h2 className="d-inline-block mb-2 text-primary-emphasis">NOI COMPRIAMO FARMACIE<br></br>per nostri selezionatissimi clienti</h2>
              <div className="my-div mb-4">Se vuoi vendere una farmacia, se vuoi comprare una farmacia,
                se vuoi una valutazione della tua farmacia,<br></br>contattaci per richiedere informazioni nella massima riservatezza</div>
              <p className="card-text mb-auto">
                <ContattiBottone etichetta=' Contattami riservatamente' />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home_Rg4;





