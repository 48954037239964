import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { VendereUnaFarmacia_Head } from './vendere-la-farmacia-head';
import { VendereUnaFarmacia_Rg1 } from './vendere-la-farmacia-rg1';
import { VendereUnaFarmacia_Corpo } from './vendere-la-farmacia-corpo';

export const VendereUnaFarmacia = () => {

  return (
    <>
      <Navbar1 />
      <VendereUnaFarmacia_Head />
      <VendereUnaFarmacia_Rg1 />
      <VendereUnaFarmacia_Corpo />
    </>
  )
}