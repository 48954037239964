import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { ComprareUnaFarmacia_Head } from './comprare_una_farmacia_head';
import { ComprareUnaFarmacia_Rg1 } from './comprare_una_farmacia_rg1';
import { ComprareUnaFarmacia_Corpo } from './comprare_una_farmacia_corpo';

export const ComprareUnaFarmacia = () => {

  return (
    <>
      <Navbar1 />
      <ComprareUnaFarmacia_Head />
      <ComprareUnaFarmacia_Rg1 />
      <ComprareUnaFarmacia_Corpo />
    </>
  )
}