import Consigli_comprare_una_farmacia from './Immagini/Consigli-comprare-una-farmacia.png';
import Consigli_compravendita_farmacie from './Immagini/Consigli-compravendita-farmacie.png';
import Consigli_vendere_una_farmacia from './Immagini/Consigli-vendere-una-farmacia.png';
import {Link} from "react-router-dom";

export const AltrePagine_Sb = () => {
    return (
        <>
            <div>
                <h4 className="fst-italic pt-4">Altre pagine</h4>
                <ul className="list-unstyled">

                    <li>
                        <Link className="d-flex flex-column flex-lg-row gap-2 align-items-center py-3 link-body-emphasis text-decoration-none border-top" 
                        to="/compravendita-farmacie">
                            <img src={Consigli_compravendita_farmacie} className="d-block mx-lg-auto img-fluid" alt="Compravendita farmacie" title="Compravendita farmacie" width="40%" loading="lazy" />
                            <div className="col-lg-6">
                                <h6 className="mb-0">Farmacontatto<br></br>Compravendita di farmacie</h6>
                                <small className="text-body-secondary">Luglio, 2023</small>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="d-flex flex-column flex-lg-row gap-2 align-items-center py-3 link-body-emphasis text-decoration-none border-top" 
                        to="/comprare-una-farmacia">
                            <img src={Consigli_comprare_una_farmacia} className="d-block mx-lg-auto img-fluid" alt="Comprare una farmacia" title="Comprare una farmacia" width="40%" loading="lazy" />
                            <div className="col-lg-6">
                                <h6 className="mb-0">Farmacontatto<br></br>Comprare una farmacia</h6>
                                <small className="text-body-secondary">Luglio, 2023</small>
                            </div>
                        </Link>
                    </li>
                    <li>
                        <Link className="d-flex flex-column flex-lg-row gap-2 align-items-center py-3 link-body-emphasis text-decoration-none border-top" 
                        to="/vendere-la-farmacia">
                            <img src={Consigli_vendere_una_farmacia} className="d-block mx-lg-auto img-fluid" alt="Vendere una farmacia" title="Vendere una farmacia" width="40%" loading="lazy" />
                            <div className="col-lg-6">
                                <h6 className="mb-0">Farmacontatto<br></br>Vendere una farmacia</h6>
                                <small className="text-body-secondary">Luglio, 2023</small>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        </>
    )
}
export default AltrePagine_Sb;