
import Compravendita_di_farmacie from './Immagini/Compravendita di farmacie.png';
import ChkOk from './Immagini/check.png';

export const Home_Rg3 = () => {
  return (
    <>
      <div className="my-div">
        <div className="Mycontainer">

          <div className="row pb-0 p-5 align-items-center"
            style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
          >
            <div className="row flex-sm-column-reverse flex-md-row">
            <div className="col-md-6 col-lg-4 order-sm-1" id='Vendere la tua farmacia'>
              <img src={Compravendita_di_farmacie} className="d-block mx-lg-auto img-fluid" alt="Compravendita farmacie" title="Compravendita farmacie" width="650" loading="lazy" />
            </div>
            <div className="col-md-6 col-lg-8 order-sm-12">
            <p style={{ color: '#f57e20', fontWeight: 'bold', fontSize: '1.1em', textAlign: 'left' }}>VENDITA FARMACIE</p>{' '}
              <h1 className="mb-2 text-primary-emphasis text-start">
                Farmacontatto, per vendere la tua farmacia</h1>
              <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                Stai pensando di vendere la tua farmacia?
              </p>
              <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                Farmacontatto ti offre il supporto per portare a termine l’operazione in modo sicuro e nella massima riservatezza.
              </p>
              <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                Contattaci per un primo incontro e nel frattempo ricorda che la riservatezza é una condizione essenziale per il buon esito di una compravendita di farmacia.
              </p>
              <p className="mb-1 text-secondary-emphasis mb-3 text-start">
                Farmacontatto ti tutela anche così..
              </p>

              <div className="row">
                <div className="add-item text-center col-sm-12 col-md-10 col-lg-12 mb-2">
                  <ul id="list" className="list-group mt-2 pb-2 gap-0 fw-bold" >
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <img src={ChkOk} alt="Compravendita farmacie" title="Compravendita farmacie" width="16" height="16" />
                      <div style={{ paddingLeft: "1em" }}>Mantieni la massima riservatezza con tutti
                      </div>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <img src={ChkOk} alt="Compravendita di farmacie" title="Compravendita di farmacie" width="16" height="16" />
                      <div style={{ paddingLeft: "1em" }}>Richiedi un incontro
                      </div>
                    </li>
                    <li className="list-group-item border-0 d-flex align-items-center">
                      <img src={ChkOk} alt="Farmacie in vendita" title="Farmacie in vendita" width="16" height="16" />
                      <div style={{ paddingLeft: "1em" }}>Raccontaci le tue esigenze, i tuoi sogni
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-grid gap-2 d-md-flex justify-content-md-start mb-4 mb-lg-3">
              <button type="button" className="btn btn-warning btn-lg">
                <a className='btn-href-black' href="/vendere-la-farmacia">Scopri di più</a></button>
              </div>
            </div>
            </div>

          </div>

        </div>

      </div>
    </>
  )
}

export default Home_Rg3;





