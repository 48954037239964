
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react"
import { Link as Links } from 'react-scroll';
import { Link } from 'react-router-dom';


import user_friends_solid from './icone/user-friends-solid.svg';
import newspaper_solid from './icone/newspaper-solid.svg';
import handshake_solid from './icone/handshake-solid.svg';
import chart_pie_solid from './icone/chart-pie-solid.svg';
import cubes_solid from './icone/cubes-solid.svg';
import user_tie_solid from './icone/user-tie-solid.svg';


export const ComprareUnaFarmacia_Rg1 = () => {
  return (
    <>
      <div className="my-div">

        <div className='text-sm'>
          <Breadcrumb spacing='8px' >
            <BreadcrumbItem>
              <Link to={`/`}>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <Links to={`/`} href='#'>comprare-una-farmacia</Links>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>

        <div className="col-12 p-4">
          <div className="col d-flex flex-column position-static" id='Perché scegliere noi'>
            <h2 className="d-inline-block text-primary-emphasis">PERCHÉ SCEGLIERE NOI</h2>
            <div className="my-div text-secondary-emphasis mb-4">
              Forse ti stai chiedendo perché dovresti scegliere noi per comprare una farmacia e non altri agenti intermediari
            </div>
          </div>
        </div>

        <div className="row pt-3 mb-5" style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
          <div className="row">
            <div className="col-lg-4">
              <img src={user_friends_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Tuteliamo entrambe le parti</h3>
              <p>Tuteliamo entrambe le parti garantendo imparzialità ed equilibrio perché sappiamo che gli affari si fanno in due.</p>
            </div>

            <div className="col-lg-4">
              <img src={newspaper_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Contratti chiari e semplici</h3>
              <p>Abbiamo voglia di semplicità e concretezza. Ci piacciono i contratti scritti in "italiano" e siamo allergici a frasi in “avvocatese”.</p>
            </div>

            <div className="col-lg-4">
              <img src={handshake_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Accompagnamo sempre</h3>
              <p>Non mandiamo mai i clienti in vista da soli, leggiamo i bilanci con loro e facciamo calcoli, selezioniamo le finanziarie e molto altro.</p>
            </div>
          </div>


          <div className="row">
            <div className="col-lg-4">
              <img src={chart_pie_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Servizi a 360° ?<br></br>
                Cosa vuol dire?</h3>
              <p>Quanti gradi coprono i nostri servizi, …360?<br></br>
                Non lo sappiamo, forse ...giudicate voi.<br></br>
                Si fa quello che serve e che va fatto.</p>
            </div>

            <div className="col-lg-4">
              <img src={cubes_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Leader di mercato?<br></br>
                No grazie!</h3>
              <p>Ci limitiamo alle trattative che possiamo seguire bene, i clienti, compratori e venditori, alla fine sono contenti e questo è l'importante.</p>
            </div>

            <div className="col-lg-4">
              <img src={user_tie_solid} alt="Generic placeholder image" width="50" height="50" />
              <h3>Professionali?<br></br>
                Forse!</h3>
              <p>Non possiamo essere noi a dirlo, e comunque non ci crederebbe nessuno, giustamente. Quindi incontriamoci e giudicate voi.</p>
            </div>
          </div>
        </div>

      </div>

    </>
  )
}

export default ComprareUnaFarmacia_Rg1;