
import { Ritorna } from './ritorna';
import { Pharmascout_Sb } from './pharmascout_sb';
import { Regioni_Sb } from './Regioni_Sb';

export const InserzioniFarmacie_Sb = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>
          <Ritorna />
          <Regioni_Sb />
          <Pharmascout_Sb />
        </div>
      </div>
    </>
  )
}

export default InserzioniFarmacie_Sb;