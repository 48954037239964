import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { CompravenditaFarmacie_Head } from './compravendita_farmacie_head';
import { CompravenditaFarmacie_Rg1 } from './compravendita_farmacie_rg1';
import { CompravenditaFarmacie_Corpo } from './compravendita_farmacie_corpo';

export const CompravenditaFarmacie = () => {

  return (
    <>
      <Navbar1 />
      <CompravenditaFarmacie_Head />
      <CompravenditaFarmacie_Rg1 />
      <CompravenditaFarmacie_Corpo />
    </>
  )
}