import React from "react";
import "./css/blog.css";
import { Navbar1 } from './navbar1';
import { Farmacieinvendita_Lista }  from './farmacie_in_vendita_lista';
import { Farmacieinvendita_Head } from './farmacie_in_vendita_head';
import { Home_Rg1 } from './Home_Rg1';

export const Farmacieinvendita = () => {

  return (
    <>
      {/*

      */}
      <Navbar1 />
      <Farmacieinvendita_Head />
      <Home_Rg1 />
      <Farmacieinvendita_Lista />
    </>
  )
}