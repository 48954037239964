import AnchorLink from 'react-anchor-link-smooth-scroll';

export const Vendere_la_farmacia_toc = () => {
  return (
    <>
          <div className="p-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign: 'left', fontSize: '1em'}}>
              <AnchorLink offset='100' href='#Come vendere la farmacia'>Come vendere la farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Noi ti aiutiamo vendere la farmacia'>Noi ti aiutiamo vendere la farmacia</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Questo è un buon momento'>Questo è un buon momento</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Chi è il meditore abilitato'>Chi è il meditore abilitato</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Collaboriamo con diverse agenzie'>Collaboriamo con diverse agenzie</AnchorLink>
              <br></br>
              <AnchorLink offset='100' href='#Mantieni la riservatezza'>Mantieni la riservatezza con tutti</AnchorLink>
            </p>
          </div>
    </>
  )
}
export default Vendere_la_farmacia_toc;