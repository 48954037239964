import AnchorLink from 'react-anchor-link-smooth-scroll';

export const CompravenditaFarmacie_Toc = () => {
  return (
    <>
          <div className="p-3 bg-body-tertiary rounded">
            <h4 className="fst-italic">Contenuti della pagina</h4>
            <p style={{textAlign:'left'}}>
              <AnchorLink className='my-AncorLink' offset='100' href='#Comprare farmacie'>Comprare farmacie</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Vuoi comprare una farmacia?'>Vuoi comprare una farmacia?</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Parleremo delle tue aspettative'>Parleremo delle tue aspettative</AnchorLink>
              <br></br><br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Vendere farmacie'>Vendere Farmacie</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Vuoi mettere la tua farmacia in vendita?'>Vuoi mettere la tua farmacia in vendita?</AnchorLink>
              <br></br>
              <AnchorLink className='my-AncorLink' offset='100' href='#Dubbi e perplessità'>Dubbi e perplessità</AnchorLink>
            </p>
          </div>
    </>
  )
}

export default CompravenditaFarmacie_Toc;