import React from "react";
import "./css/blog.css";
import { Link as Links } from 'react-scroll';
import { Navbar1 } from './navbar1';
import { Chisiamo_Head } from './chisiamo_head';
import { Chisiamo_Rg1 } from './chisiamo_rg1';
import { Chisiamo_Rg2 } from './chisiamo_rg2';
import { Chisiamo_Rg3 } from './chisiamo_rg3';
import { Home_Rg4 } from './Home_Rg4';

export const Chisiamo = () => {

  return (
    <>
      <Navbar1 />
      <Chisiamo_Head />
      <Chisiamo_Rg1 />
      <Chisiamo_Rg2 />
      <Chisiamo_Rg3 />
      <Home_Rg4 />
    </>
  )
}