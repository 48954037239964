
import Farmacie_in_vendita from './Immagini/Farmacie-in-vendita.jpg';
import { Helmet, HelmetProvider } from 'react-helmet-async';

export const InserzioniFarmacie_Head = () => {
  return (
    <>

      <HelmetProvider>
        <Helmet>
          <title>Inserzioni e annunci farmacie in vendita - Farmacontatto</title>
          <meta name="description" content={'⭐ elenco Farmacie in vendita. ⭐ Inserzioni compravendita farmacie.'} />
        </Helmet>
      </HelmetProvider>

      <div className="px-5 text-center border-bottom" id='Compravendita di farmacie' style={{ backgroundColor: "#d9eef3" }}>
        <div className="mycontainer col-xxl-12">
          <div className="row align-items-center ">
            <div className="col pt-5 col-sm-12 col-lg-5">
              <img src={Farmacie_in_vendita} className="d-block mx-lg-auto img-fluid" alt="Farmacie in vendita" title="Farmacie in vendita" width="700" height="500" loading="lazy" />
            </div>
            <div className="col-lg-7 mb-3"
              style={{
                borderRadius: '20px',
                backgroundColor: '#f7f9fc',
                padding: '20px'
              }}>
              <h1 className="display-5 fw-bold text-body-emphasis lh-1 mb-3"
                style={{
                  textShadow: '-3px 3px 6px #A39090',
                  fontSize: '3.5em',
                  WebkitTextStrokeWidth: '1px',
                  WebkitTextStrokeColor: '#54595f'
                }}>
                <font color="#5672AD">Farmacie in vendita</font></h1>
              <h2 className="lead fw-bold"
                style={{
                  textShadow: '-1px 1px 2px #A39090',
                  fontSize: '2em',
                }}>
                <font color="#5672AD">
                  ... le trovi anche su Pharmascout
                </font>
              </h2>
              <div className="d-grid gap-2 d-md-flex justify-content-md-center">
                <button type="button" className="btn btn-warning btn-lg px-4 me-md-2"

                >Vai su Pharmascout.it</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InserzioniFarmacie_Head;