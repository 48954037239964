
import voglio_comprare_una_farmacia from './Immagini/voglio-comprare-una-farmacia.png';
import voglio_vendere_la_farmacia from './Immagini/voglio-vendere-la-farmacia.png';

import { CompravenditaFarmacie_Sb } from './compravendita_farmacie_sb';
import ContattiLink from './ContattiLink';
import ContattiBottone from './ContattiBottone';

export const CompravenditaFarmacie_Corpo = () => {

  return (
    <>
      <div className='my-div'>
        <div className="row g-2">
          <div className="col-md-9">

            <h2 className="pb-2 mb-4 fst-italc">
              Farmacontatto, per una compravendita di farmacie
            </h2>
            <article className="blog-post p-3"
              style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}>
              <p className="blog-post-meta"> Luglio, 2023 di
                <ContattiLink nome=' Achille Bonzini' />
              </p>
              <div className="row flex-md-row">
                <div className="col-md-6 col-sm-12-pt-5 order-sm-2 my-auto" id='Comprare una farmacia'>
                  <img src={voglio_comprare_una_farmacia} className="d-block mx-lg-auto img-fluid" alt="Comprare una farmacia" title="Comprare una farmacia" width="720" loading="lazy" />
                </div>

                <div className="col-md-6 col-lg-6 col-sm-12-pt-5 order-sm-1 p-" id='Comprare farmacie'>
                  <p style={{ color: '#f57e20', fontWeight: 'bold' }}>COMPRARE FARMACIE</p>
                  <div style={{ textAlign: 'left' }} id='Vuoi comprare una farmacia?'>
                    <h3>Vuoi comprare una farmacia?</h3>
                    <p>
                      Vuoi comprare una farmacia, ma non sai da che parte iniziare?
                    </p>
                    <p>
                      Si tratta di un’operazione molto complessa e delicata che va supportata da professionisti del settore, preparati e aggiornati sui continui cambiamenti del mercato della farmacia.
                    </p>
                    <p>
                      Farmacontatto ti offre i servizi di cui hai bisogno per portare a termine la difficile operazione di compravendita.
                    </p>
                    <p>
                      Sarà nostra premura dopo un vostro primo contatto organizzare un incontro conoscitivo nella massima riservatezza.
                    </p>
                  </div>
                </div>

                <div className="col-12- pt-4 order-3">
                  <div style={{ textAlign: 'left' }} id='Parleremo delle tue aspettative'>
                    <h3>Parleremo delle tue aspettative</h3>
                    <p>
                      Parleremo delle tue aspettative lavorative ed economiche, delle tue necessità personali, familiari e logistiche.
                    </p>
                    <p>
                      Questo è molto importante per noi per poter trovare la farmacia più adatta a te, nella zona che ti interessa, in linea con il tuo budget d’investimento e con la percentuale da finanziare.</p>
                    <p>
                      Farmacontatto fornisce informazioni sulle farmacie in vendita esclusivamente a seguito di incontri conoscitivi e nella massima riservatezza, garantendo nelle prime fasi della trattativa l’anonimato di entrambe le parti in causa.</p>
                    <p>
                      Sarà nostra premura dopo un vostro primo contatto organizzare un incontro conoscitivo nella massima riservatezza.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row flex-md-row pt-5">
                <div className="col-md-6 col-sm-12-pt-5 order-sm-2 my-auto" id='Comprare una farmacia'>
                  <img src={voglio_vendere_la_farmacia} className="d-block mx-lg-auto img-fluid" alt="Vendere una farmacia" title="Vendere una farmacia" width="720" loading="lazy" />
                </div>
                <div className="col-md-6 col-lg-6 col-sm-12-pt-5 order-sm-1" id='Vendere farmacie'>
                  <p style={{ color: '#f57e20', fontWeight: 'bold' }}>VENDERE FARMACIE</p>
                  <div style={{ textAlign: 'left' }} id='Vuoi mettere la tua farmacia in vendita?'>
                    <h3>Vuoi mettere la tua farmacia in vendita?</h3>
                    <p>
                      Stai pensando di vendere la tua farmacia? Non sei ancora deciso?
                    </p>
                    <p>
                      Ti offriamo la consulenza di professionisti seri che conoscono molto bene il mercato delle farmacie, in grado di fare una valutazione della tua farmacia per poterla proporre sul mercato al prezzo giusto.
                    </p>
                  </div>
                </div>

                <div className="col-12- pt-4 order-3">
                  <div style={{ textAlign: 'left' }} id='Dubbi e perplessità'>
                    <h3>Dubbi e perplessità</h3>
                    <p>
                      Se hai ancora dubbi o perplessità potrai consultarti con professionisti preparati del settore per maturare la tua decisione con serenità e consapevolezza.
                    </p>
                    <p>
                      Ti aiuteremo a capire quanto sarà effettivamente il carico fiscale a cui andrai incontro e il guadagno netto della vendita al saldo delle spese e delle imposte.
                      L’imposizione fiscale, infatti, potrebbe notevolmente variare anche a seconda che si tratti della cessione di una ditta individuale o cessione di quote, in caso di società.
                    </p>
                    <p>
                      Farmacontatto fornisce informazioni sulle farmacie in vendita esclusivamente a seguito di incontri conoscitivi e nella massima riservatezza, garantendo nelle prime fasi della trattativa l’anonimato di entrambe le parti in causa.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 pt-4"
                  style={{ backgroundImage: "linear-gradient(180deg, #F7F9FC, #FFFFFF)" }}
                >
                  <div className="row g-0 overflow-hidden flex-md-row">
                    <div className="col d-flex flex-column position-static" id='Noi compriamo la tua farmacia'>

                      <h2 className="d-inline-block mb-2 text-primary-emphasis">E TU?</h2>
                      <h3 className="mb-0 text-primary-emphasis mb-3">Vorresti comprare una farmacia o ne hai una da vendere?
                        <br></br>Contattami per ulteriori chiarimenti.</h3>

                      <p className="card-text mb-auto">
                        <ContattiBottone etichetta=' Scrivimi in privato' />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </div>
          < CompravenditaFarmacie_Sb />
        </div>
      </div>

    </>
  )
}
export default CompravenditaFarmacie_Corpo;