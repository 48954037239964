
import { Vendere_la_farmacia_toc } from './vendere_la_farmacia_toc';
import { AltrePagine_Sb } from './altre_pagine_sb';
import { Pharmascout_Sb } from './pharmascout_sb';

export const VendereUnaFarmacia_Sb = () => {
  return (
    <>
      <div className="col-md-3">
        <div className="position-sticky" style={{ top: '2rem' }}>

          <Vendere_la_farmacia_toc />
          <AltrePagine_Sb />
          <Pharmascout_Sb />
        </div>
      </div>
    </>
  )
}

export default VendereUnaFarmacia_Sb;